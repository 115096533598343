body {
  font-family: 'Circular Std', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #121212; /* Spotify dark theme */
  color: #ffffff; /* Default text color */
}

@font-face {
  font-family: 'Circular Std';
  src: url('/fonts/circular-std.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
