.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two playlists per row */
    gap: 15px;
    justify-content: center;
  }
  
  .big-button-container {
    margin-top: 15px; /* Space between the playlists and the button */
    grid-column: span 2; /* Make the button span the entire width of two columns */
    display: flex;
    justify-content: center;
  }
  
  .big-button {
    position: relative;
    width: 100%; /* Matches the width of two playlists */
    height: 120px; /* Double the playlist card height */
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .big-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/wrapped.svg');
    background-size: cover;
    background-position: center;
    transform-origin: center;
    animation: backgroundZoomEffect 5s infinite alternate ease-in-out;
    z-index: 1;
  }
  
  .big-button-text {
    position: relative;
    z-index: 2;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
  }
  
  @keyframes backgroundZoomEffect {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  